var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{staticClass:"px-4 py-2",attrs:{"color":"deep-purple darken-2","icon":"mdi-bank"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('dashboardtitleDetails'))+" ")])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.BuybackDetailHeaders,"items":_vm.dashboardDetails,"item-key":"lineNumber","hide-default-footer":"","single-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t('subTitledashboardDetails'))+" ")])]},proxy:true},{key:"item.partnerName",fn:function(ref){
var item = ref.item;
return [(item.partnerName.length != 0)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.partnerName)+" ")]):_vm._e()]}},{key:"item.intransitWasteQuantity",fn:function(ref){
var item = ref.item;
return [(item.recordType == 99)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(Number(item.intransitWasteQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")]):_c('div',[_vm._v(" "+_vm._s(Number(item.intransitWasteQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.intransitProductQuantity",fn:function(ref){
var item = ref.item;
return [(item.recordType == 99)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(Number(item.intransitProductQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")]):_c('div',[_vm._v(" "+_vm._s(Number(item.intransitProductQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.availableQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.availableQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.totalQuantity",fn:function(ref){
var item = ref.item;
return [(item.recordType == 99)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(Number(item.totalQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")]):_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(Number(item.totalQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.designatedQuantity",fn:function(ref){
var item = ref.item;
return [(item.recordType == 99)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(Number(item.designatedQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")]):_c('div',[_vm._v(" "+_vm._s(Number(item.designatedQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.notDesignatedQuantity",fn:function(ref){
var item = ref.item;
return [(item.recordType == 99)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(Number(item.notDesignatedQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")]):_c('div',[_vm._v(" "+_vm._s(Number(item.notDesignatedQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}}])}),_c('error-dialog',{attrs:{"dialog":_vm.showErrorDialog,"title":_vm.$t('errorTitleProcess'),"message":_vm.errorMessage},on:{"update:dialog":function($event){_vm.showErrorDialog = false}}}),_c('process-overlay',{attrs:{"processing":_vm.modelProcessing}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }