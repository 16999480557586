<template>
  <base-material-card
    color="deep-purple darken-2"
    icon="mdi-bank"
    class="px-4 py-2"
  >
    <template v-slot:after-heading>
      <div
        class="display-2 font-weight-bold"
      >
        {{ $t('dashboardtitleDetails') }}
      </div>
    </template>
    <v-card-text>
      <v-data-table
        :headers="BuybackDetailHeaders"
        :items="dashboardDetails"
        item-key="lineNumber"
        hide-default-footer
        single-select
        class="elevation-1"
      >
        <template v-slot:top>
          <div
            class="pb-2"
          >
            {{ $t('subTitledashboardDetails') }}
          </div>
        </template>
        <template v-slot:item.partnerName="{ item }">
          <div
            v-if="item.partnerName.length != 0"
            class="font-weight-bold"
          >
            {{ item.partnerName }}
          </div>
        </template>
        <template v-slot:item.intransitWasteQuantity="{ item }">
          <div
            v-if="item.recordType == 99"
            class="font-weight-bold"
          >
            {{ Number(item.intransitWasteQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
          <div
            v-else
          >
            {{ Number(item.intransitWasteQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
        </template>
        <template v-slot:item.intransitProductQuantity="{ item }">
          <div
            v-if="item.recordType == 99"
            class="font-weight-bold"
          >
            {{ Number(item.intransitProductQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
          <div
            v-else
          >
            {{ Number(item.intransitProductQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
        </template>
        <template v-slot:item.availableQuantity="{ item }">
          <div>
            {{ Number(item.availableQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
        </template>
        <template v-slot:item.totalQuantity="{ item }">
          <div
            v-if="item.recordType == 99"
            class="font-weight-bold"
          >
            {{ Number(item.totalQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
          <div
            v-else
            class="font-weight-bold"
          >
            {{ Number(item.totalQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
        </template>
        <template v-slot:item.designatedQuantity="{ item }">
          <div
            v-if="item.recordType == 99"
            class="font-weight-bold"
          >
            {{ Number(item.designatedQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
          <div
            v-else
          >
            {{ Number(item.designatedQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
        </template>
        <template v-slot:item.notDesignatedQuantity="{ item }">
          <div
            v-if="item.recordType == 99"
            class="font-weight-bold"
          >
            {{ Number(item.notDesignatedQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
          <div
            v-else
          >
            {{ Number(item.notDesignatedQuantity).toFixed(2) }} {{ item.unitText }}
          </div>
        </template>
      </v-data-table>
      <error-dialog
        :dialog="showErrorDialog"
        :title="$t('errorTitleProcess')"
        :message="errorMessage"
        @update:dialog="showErrorDialog = false"
      />
      <process-overlay
        :processing="modelProcessing"
      />
    </v-card-text>
  </base-material-card>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import dashboardAPI from '@/services/dashboard'
  export default {
    name: 'CommitmentDetails',
    components: {
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    props: {
      type: {
        type: String,
        default: '1',
      },
    },
    data: function () {
      return {
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
        BuybackDetailHeaders: [
          {
            text: this.$t('col-dashboard-Partner'),
            align: 'start',
            value: 'partnerName',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('col-dashboard-ProductGroup'),
            align: 'start',
            value: 'productName',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '18%',
          },
          {
            text: this.$t('col-dashboard-Receiving'),
            align: 'end',
            value: 'intransitWasteQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '10%',
          },
          {
            text: this.$t('col-dashboard-Shipping'),
            align: 'end',
            value: 'intransitProductQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '10%',
          },
          {
            text: this.$t('col-dashboard-Available'),
            align: 'end',
            value: 'availableQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '10%',
          },
          {
            text: this.$t('col-dashboard-Total'),
            align: 'end',
            value: 'totalQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '10%',
          },
          {
            text: this.$t('col-dashboard-Designated'),
            align: 'end',
            value: 'designatedQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '10%',
          },
          {
            text: this.$t('col-dashboard-NotDesignated'),
            align: 'end',
            value: 'notDesignatedQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '10%',
          },
        ],
        dashboardDetails: [],
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
      }),
    },
    mounted: function () {
      this.modelProcessing = true
      dashboardAPI.getDashboardCommitmentDetails(this.type)
        .then(response => {
          this.dashboardDetails = response.data.details
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
    },
  }
</script>
